import React from 'react';
import TypingText from './TypingText'; 

function MainPage() {
  return (
    <div className="MainPage">
      <TypingText /> {}
      <div className="ProgressButton"> 
      </div>
    </div>
  );
}

export default MainPage;